@import "~antd/dist/antd.css";
/* @import url("https://fonts.googleapis.com/css?family=Nunito&display=swap"); */
@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@200;300;400;500;600;700&display=swap");

@import "./style/variables";
/* @import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&family=Red+Hat+Display:wght@400;500;700;900&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400;700&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500;600;700&display=swap"); */

#root {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* font-family: "Fira Sans", sans-serif; */
  font-family: "Lato", sans-serif;
  // font-family: "Open Sans", sans-serif;
  color: var(--secondary-color);
  /* font-family: "Poppins", sans-serif; */
  /* font-family: "Red Hat Display", sans-serif; */
  /* font-family: "Muli", sans-serif; */
}

.heading-title {
  display: flex;
  align-items: center;
}

.ia-btn {
  font-size: small;
  margin: 10px 0;
  --border-size: 2px;
  --border-angle: 0turn;
  animation: bg-spin 3s linear infinite;
  animation-play-state: paused;
  background-image: conic-gradient(
      from var(--border-angle),
      #fff,
      #fff 50%,
      #fff
    ),
    conic-gradient(from var(--border-angle), transparent 20%, #ff1d25, #051d4f);
  background-image: conic-gradient(
      from var(--border-angle),
      #fff,
      #fff 50%,
      #fff
    ),
    conic-gradient(
      from var(--border-angle),
      transparent 20%,
      var(--primary-color),
      var(--secondary-color)
    );
  background-size: calc(100% - var(--border-size) * 2)
      calc(100% - var(--border-size) * 2),
    cover;
  background-position: 50%;
  background-repeat: no-repeat;
}

.ia-btn:hover {
  animation-play-state: running;
  --border-size: 2px;
  --border-angle: 0turn;
  background-image: conic-gradient(
      from var(--border-angle),
      #fff,
      #fff 50%,
      #fff
    ),
    conic-gradient(from var(--border-angle), transparent 20%, #ff1d25, #051d4f);
  background-image: conic-gradient(
      from var(--border-angle),
      #fff,
      #fff 50%,
      #fff
    ),
    conic-gradient(
      from var(--border-angle),
      transparent 20%,
      var(--primary-color),
      var(--secondary-color)
    );
  background-size: calc(100% - var(--border-size) * 2)
      calc(100% - var(--border-size) * 2),
    cover;
  background-position: 50%;
  background-repeat: no-repeat;
}

@keyframes bg-spin {
  100% {
    --border-angle: 2turn;
  }
}

.header-review-card {
  display: flex;
  justify-content: space-between;
}

.reply-templates-picker {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;

  .templates-picker {
    margin-top: 0px;
  }
}

.empty-reply-template {
  margin: 20px auto;
}

.see-more-templates {
  display: flex;
  align-items: center;
  margin-left: 20px;
  font-size: 15px;
  color: #ff4e4e;
  text-decoration: underline;
  cursor: pointer;
  justify-content: center;
}

.filters-plus {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .filter-groups {
    margin: 0px;
  }
}

.filters-plus .ant-input {
  height: 40px;
}

.heading-title > img {
  margin-right: 10px;
}

.ant-list-item-meta-title {
  text-align: left;
}
.ant-list-item-meta-description {
  text-align: left;
}

.header-features {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ant-progress-text {
  color: #001529 !important;
  text-shadow: none !important;
}
.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 0px;
}

.ant-select-selection--single {
  height: 40px;
}

.ant-btn {
  height: 38px;
  border-radius: 8px;
}

.ant-btn-primary {
  border: 0 !important;
  color: #fff !important;
  background-color: var(--primary-color) !important;
  border-radius: 8px;
  font-size: 14px;
  font-family: "Open Sans", sans serif;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  height: 38px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  .plus-btn {
    margin-right: 9px;
  }
  .plus-btn path {
    stroke: #fff;
  }
  .icon-after {
    display: flex;
    margin-left: 9px;
  }
}

.ant-btn-new-secondary {
  //styleName: Text/lg/Semibold;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #353b44;
  background-color: #f7f8f9;
  border: 1px solid #f7f8f9 !important;
  border-radius: 8px;
  height: 38px;
  align-items: center;
  display: flex;
  justify-content: center;

  .icon-after {
    margin-left: 9px;
    display: flex;
  }
}

.ant-btn-secondary {
  border: 1px solid var(--primary-color) !important;
  color: var(--primary-color) !important;
  background-color: transparent !important;
  font-family: "Open Sans", sans serif;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  font-size: 14px;
  border-radius: 8px;
  height: 38px !important;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  .plus-btn {
    margin-right: 9px;
  }
  .plus-btn path {
    stroke: var(--primary-color);
  }
}

.ant-btn-secondary:hover {
  background-color: var(--primary-color) !important;
  color: #fff !important;
  .plus-btn path {
    stroke: #fff;
  }
}

.ant-input-search-button.ant-btn-primary {
  height: 32px;
}

.ant-btn-thirdary {
  border: none;
}
.anticon.anticon-check-square {
  color: #3ecb3e;
}

.ant-pagination-item-active a {
  color: var(--primary-color);
}

.ant-pagination-item-active {
  border-color: var(--primary-color);
}

.ant-pagination-item-active:focus,
.ant-pagination-item-active:hover {
  border-color: var(--primary-color);
}

.ant-pagination-item:focus,
.ant-pagination-item:hover {
  border-color: var(--primary-color);
}

.ant-pagination-item:focus a,
.ant-pagination-item:hover a {
  color: var(--primary-color);
}

.ant-pagination-item-active:focus a,
.ant-pagination-item-active:hover a {
  color: var(--primary-color);
}
.anticon.anticon-warning {
  color: orange;
}
.ant-collapse-content {
  color: rgba(0, 0, 0, 0.65) !important;
}
.ant-select-selection:hover {
  border-color: var(--primary-color, 0.4);
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: #001529 !important;
}
.ant-select-dropdown-menu-item:hover:not(
    .ant-select-dropdown-menu-item-disabled
  ) {
  background-color: transparent !important;
  border-left: 3px solid var(--primary-color);
}
.ant-select-dropdown-menu-item-active:not(
    .ant-select-dropdown-menu-item-disabled
  ) {
  background-color: transparent !important;
  border-left: 3px solid var(--primary-color);
}

.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
  border-color: #f9727247;
}
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: #f4f7fd;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  border-radius: 4px;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 0px;
}

.ant-table-thead > tr > th {
  background: transparent !important;
  font-weight: bold;
  color: grey;
}

.ant-table-tbody > tr > td {
  border-bottom: none;
}
.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
  box-shadow: none;
}

.ant-alert-success {
  background-color: #31d79f26;
  border: 1px solid #31d79f;
  margin-bottom: 20px;
}

.ant-table-thead
  > tr
  > th
  .ant-table-column-sorter
  .ant-table-column-sorter-inner
  .ant-table-column-sorter-up.on,
.ant-table-thead
  > tr
  > th
  .ant-table-column-sorter
  .ant-table-column-sorter-inner
  .ant-table-column-sorter-down.on {
  color: var(--primary-color);
}

.ant-table-tbody
  > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td {
  background: #f7ecec7a;
}

.ant-btn-primary[disabled] {
  opacity: 0.3;
}

.ant-btn[disabled] {
  opacity: 0.5;
}

.ant-steps-item-process .ant-steps-item-icon {
  background: transparent !important;
}
.btn-primary {
  background: var(--primary-color);
  border: 0;
  width: fit-content;
  padding: 5px 15px;
  cursor: pointer;
  color: #fff;
  border-radius: 3px;
  font-size: 16px;
  font-weight: 600;
}

.ant-select-selection--single {
  display: flex;
}
.ant-collapse-borderless {
  box-shadow: rgba(114, 114, 116, 0.12) 0px 1px 1px 0px;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
}

.ant-menu-item > a:hover {
  color: var(--primary-color);
}

.ant-menu-item-selected > a,
.ant-menu-item-selected > a:hover {
  color: var(--primary-color);
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: 4px solid var(--primary-color);
  border-radius: 4px;
}

.ant-menu-item-selected {
  color: var(--primary-color);
}

.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: var(--primary-color);
}
.ant-btn:hover,
.ant-btn:focus {
  color: #001529;
  border-color: #001529;
}

.ant-btn-primary:active,
.ant-btn-primary.active {
  background-color: #001529bf;
  border-color: #001529bf;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background-color: #fff;
  border-color: #001529de;
  color: #001529de;
}
.star {
  color: #facb32;
}

a {
  opacity: 0.85;
  color: var(--primary-color);
}

a.import {
  opacity: 1;
}
button.ant-alert-close-icon {
  height: 20px !important;
}
a:hover {
  opacity: 0.7;
  color: var(--primary-color);
}

.App {
  height: 100%;
}

.ant-input:focus {
  box-shadow: 0 0 0 2px rgba(255, 78, 78, 0.1);
}
.ant-switch-checked {
  background-color: var(--primary-color);
  opacity: 0.8;
}
.demo-infinite-container {
  /* border: 1px solid #e8e8e8; */
  border-radius: 4px;
  overflow: auto;
  height: 99vh;
}
.demo-loading-container {
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
}
.inspiration {
  float: right;
  font-size: 12px;
  margin-bottom: 8px;
}
.inspiration:hover {
  color: #007bff;
}
.inspiration > a {
  color: grey;
}

.detail_profile {
  font-size: 13px;
  color: #7c7c7c;
  font-weight: normal;
}

.skeleton-profile {
  padding: 30px;
}

.ant-select-enabled {
  width: 100px;
}

.ant-card {
  border-radius: 5px;
}

.ant-tabs {
  width: 100%;
  margin-top: 10px;
}

:root .ant-tabs-tab-prev-icon-target,
:root .ant-tabs-tab-next-icon-target {
  color: var(--primary-color);
}

.ant-tabs-ink-bar {
  background-color: var(--primary-color) !important;
  opacity: 0.6;
}

.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover {
  color: var(--primary-color);
}

.ant-table-placeholder {
  border-bottom: none !important;
}
::selection {
  background: var(--primary-color);
  opacity: 0.6; /* WebKit/Blink Browsers */
}

.ant-breadcrumb a:hover {
  color: var(--primary-color);
}

.ant-layout-sider-trigger {
  background-color: #fff !important;
  color: var(--primary-color);
  transition: all 0.1s;
}

.ant-progress-success-bg,
.ant-progress-bg {
  background-color: var(--primary-color);
  opacity: 0.8;
}

.ant-list-sm .ant-list-item {
  border-bottom: none;
}

.ant-tabs-bar {
  margin: 0px 0px 25px 0px;
}

.consult-report .ant-progress {
  margin-top: -5px;
}

.ant-result-title {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.65);
}

.ant-steps-item-process .ant-steps-item-icon {
  background: var(--primary-color);
}

.ant-steps-item-process .ant-steps-item-icon {
  border-color: var(--primary-color);
}

.ant-steps-item-finish .ant-steps-item-icon {
  border-color: var(--primary-color);
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: var(--primary-color);
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: var(--primary-color);
}

.ant-steps
  .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
  > .ant-steps-item-container[role="button"]:hover
  .ant-steps-item-icon {
  border-color: var(--primary-color);
}

.ant-steps
  .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
  > .ant-steps-item-container[role="button"]:hover
  .ant-steps-item-icon
  .ant-steps-icon {
  color: var(--primary-color);
}

.ant-steps
  .ant-steps-item:not(.ant-steps-item-active)
  > .ant-steps-item-container[role="button"]:hover
  .ant-steps-item-title,
.ant-steps
  .ant-steps-item:not(.ant-steps-item-active)
  > .ant-steps-item-container[role="button"]:hover
  .ant-steps-item-subtitle,
.ant-steps
  .ant-steps-item:not(.ant-steps-item-active)
  > .ant-steps-item-container[role="button"]:hover
  .ant-steps-item-description {
  color: var(--primary-color);
}
.ant-popover-placement-left,
.ant-popover-placement-leftTop,
.ant-popover-placement-leftBottom {
  max-width: 350px;
}

.ant-popover-placement-top {
  width: 40%;
}

.ant-list-item-meta-description {
  color: rgba(0, 0, 0, 0.75);
}

.ant-slider-handle.ant-tooltip-open {
  border-color: var(--primary-color);
}

.ant-slider-dot-active {
  border-color: #ff4e4e4d;
}

.ant-slider-track {
  background-color: #ff4e4e4d;
}

.ant-slider:hover .ant-slider-track {
  background-color: #ff4e4e4d;
}

.ant-slider-handle:focus {
  box-shadow: 0 0 0 5px rgba(255, 78, 78, 0.2);
}

.ant-slider-handle {
  border: 2px solid #ff4e4e4d;
}

.ant-slider-handle:focus {
  border-color: var(--primary-color);
}

.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: #ff4e4e4d;
}

.ant-select-selection--multiple {
  min-height: 60px;
}

.ant-select {
  height: fit-content !important;
}

.ant-steps-item {
  margin: 20px auto;
}

.ant-btn-validation {
  font-size: 15px;
  border-color: #31d79f;
  background-color: #31d79f;
  color: #fff;
  font-weight: 600;
  min-width: 250px;
  margin-bottom: 30px;
}

.ant-btn-validation:hover {
  font-size: 15px;
  border-color: #31d79f;
  background-color: transparent;
  color: #31d79f;
  font-weight: 600;
}

.ant-btn-secondary-validation {
  font-size: 15px;
  border-color: #31d79f;
  background-color: transparent;
  color: #31d79f;
  font-weight: 600;
  min-width: 250px;
  margin-bottom: 30px;
}

.ant-btn-secondary-validation:focus,
.ant-btn-secondary-validation:hover,
.ant-btn-secondary-validation:active {
  font-size: 15px;
  border-color: #31d79f;
  background-color: transparent;
  color: #31d79f;
  font-weight: 600;
}

.ant-btn:focus {
  color: #7d7d7d;
  border-color: #7d7d7d;
}

.ant-steps-item-finish
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background: var(--primary-color);
}

.ant-steps-item-process
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background: var(--primary-color);
}

.error-page {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 100px auto;
  width: 800px;
  border-radius: 20px;
  background-color: #fff3ea;
  padding: 60px;
  height: fit-content;
  text-align: center;
  .error-message {
    font-size: 18px;
    color: var(--secondary-color);
  }
  img {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}

@media screen and (max-width: 800px) {
  .ant-btn-validation {
    min-width: 100%;
  }
}
